<template>
	<div>
		<a-spin :spinning="loading" tip="logining..">
			<div style="height: 100vh; width: 100%;"></div>
		</a-spin>
	</div>
</template>

<script>
	export default{
		data(){
			return {
				loading:false
			}
		},
		created(){
		},
		methods:{

		}
	}
</script>

<style>

</style>
